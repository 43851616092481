import React from "react"
import { Link } from "gatsby"
import { Accordion, Card, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import '../components/font-awesome';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PdfPreview } from "../components/pdfPreview"
import GeneralForm from "../components/general-form"
import Map from "../components/map"


const questions = [
  {
    pregunta: "¿CÓMO ME INSCRIBO?",
    respuesta: "Admisión y vas a encontrar el formulario correspondiente."
  },
  {
    pregunta: "¿QUÉ ES LA ADMISIÓN?",
    respuesta: "Es el proceso por el cual vas a iniciar tu ingreso a la carrera. El costo se abona por única vez y depende de la carrera que elijas y la época del año en que completes el formulario. Seleccionas la carrera a la que deseas inscribirte, presiona el botón que dice Inicia tu AdmisIón."
  },
  {
    pregunta: "¿CUÁLES SON LOS REQUISITOS?",
    respuesta: "Para poder cursar un posgrado debes contar con título de grado. En caso de no cumplir con ese requisito puedes ingresar vÍa excepción de ley, tal como lo establece la Ley de Educación superior."
  },
  {
    pregunta: "¿QUÉ DOCUMENTACIÓN DEBO PRESENTAR?",
    respuesta: "Debes presentar copia del título de grado, copia de DNI completo, cartas de recomendación. En caso de no poseer título de grado, presentarás analítico del secundario y la carta de pedido de excepción de ley. Una vez iniciado el proceso, un asesor te informará cómo proceder."
  },
  {
    pregunta: "¿CUÁL ES LA DIFERENCIA ENTRE ADMISIÓN Y MATRICULACIÓN?",
    respuesta: "La admisión consta de varios pasos y es el proceso por el cual se inicia la inscripción a la carrera. Una vez admitido realizarás el proceso de matriculación que te habilitará para el cursado de la carrera."
  },
  {
    pregunta: "¿CUÁLES SON LAS MODALIDADES DE CURSADO?",
    respuesta: "Tenemos carreras que se cursan 100% presencial, 100% presencial vía zoom, híbridas y otras 100% a distancia."
  },
  {
    pregunta: "MÉTODOS DE PAGO",
    respuesta: "Haciendo click en el siguiente link podrás consultar las formas de pagos habituales.",
    link: "https://ucc.edu.ar/asuntos-economicos"
  },
]

const PreguntasFrecuentes = ({data}) => (

  <Layout>
  <SEO title="Nuestra Historia - ICDA" />

  <section>
    <div>
      <div className="w-100 d-flex bg-light inst-img">
        <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
          <h2 className="mb-4 fw-bold text-uppercase title">Preguntas Frecuentes</h2>
          <h3>Consultanos</h3>
        </div>
      </div>
      <div>
        <div className="container my-5 main">
          <div className="my-5">
            <h4 className="font-weight-bold">
              PREGUNTAS FRECUENTES
            </h4>
            
            <Accordion defaultActiveKey="">
              {questions.map((question, index) => {
                return (
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 accordion-title cursor-pointer">
                          <h6 className="mb-0">
                            {question.pregunta}
                          </h6>
                        </div>
                        <div className="mr-2">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index + 1}>
                      <Card.Body>
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="d-flex align-items-center my-2 text-secondary">
                              <h6  className="mb-0 mr-2 font-weight-bold">-</h6>
                              <h6 className="mb-0">{question.respuesta}</h6>
                              {question.link && 
                                <div className="ml-5">
                                  <Link to={question.link} className="btn btn-primary">
                                    Ver
                                  </Link>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )})}
              </Accordion>
            </div>

            <div className="my-5">
              <h4 className="font-weight-bold">
                Conocé la Universidad
              </h4>
              <Link to="https://www.ucc.edu.ar/" className="btn btn-primary">
                Ir a Universidad Católica Córdoba
              </Link>
            </div>
            
            <div className="my-5">
              <PdfPreview pdfPreview={data.strapiAcademicUnits.pdf.pdf}/>
            </div>
  
            <div className="my-5">
              <Row>
                <Col xs={12} md={6}>
                  <GeneralForm careers={data.dropdownCareerForm} />
                </Col>
                <Col xs={12} md={6}>
                  <div className="mb-3">
                    <Map />
                  </div>
                  <div className="d-flex">
                    <h6>
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                    </h6>
                    <h6>Obispo Trejo 323, Córdoba, Córdoba, Argentina</h6>
                  </div>
                  <div className="d-flex">
                    <h6>
                      <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
                    </h6>
                    <h6>(+54) 4213213</h6>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PreguntasFrecuentes

export const coursesQuery = graphql`
  query {
    strapiAcademicUnits(name: { eq: "ICDA" }) {
      pdf {
        pdf {
          code
          id
          title
        }
      }
    }
    dropdownCareerForm: allStrapiIcdaCareers(
      filter: { academic_unit: { name: { eq: "ICDA" } } }
    ) {
      edges {
        node {
          name
          webcentrix {
            career
            academicUnit
            level
            agent
          }
        }
      }
    }
  }
`
